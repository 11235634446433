<template>
  <div>
    <div class="d-flex flex-column flex-root min-vh-100">
      <!--begin::Authentication - Sign-in -->
      <div class="d-flex flex-column flex-lg-row flex-column-fluid">
        <div class="d-flex flex-column flex-lg-row-fluid w-xl-50 vh-100 overflow-auto pb-3">
          <auth-header/>
          <!--begin::Content-->
          <div class="d-flex flex-center flex-column flex-column-fluid">
            <!--begin::Wrapper-->
            <div class="form-wrapper mx-auto w-100">
              <!--begin::Form-->
              <router-view @title="title = $event" class="login-form-wrapper"></router-view>
              <!--end::Form-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Content-->
          <!--begin::Footer-->
          <app-footer/>
          <!--end::Footer-->
        </div>
        <div class="w-xl-50 positon-xl-relative d-none d-sm-block">
          <!--begin::Wrapper-->
          <slide-view/>
          <!--end::Wrapper-->
        </div>
      </div>
      <!--end::Authentication - Sign-in-->
    </div>
  </div>
</template>

<script>
import AuthHeader from './components/AuthHeader.vue';
import AppFooter from '@/components/elements/custom/AppFooter.vue';
import SlideView from './components/SlideView.vue';

export default {
  components: {
    AuthHeader,
    AppFooter,
    SlideView,
  },
  name: 'auth',
  data: () => ({
    title: null
  }),
  computed: {
    isLogin() {
      return this.$route.name === 'login';
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<style lang="scss" scoped>
.form-wrapper {
  padding: 50px 75px;

  .login-form-wrapper {
    background: #FFF;
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .form-wrapper {
    padding: 75px 0;

    .login-form-wrapper {
      border-radius: 10px;
    }
  }
}
</style>