<template>
  <div class="fs-6 px-10 px-sm-5 pt-5 footer m-0 mx-sm-10" :class="mode">
    <div class="d-flex justify-content-between font-weight-bolder">
      <div>
        <div class="h6" :class="{'text-white': mode === 'white'}">{{ $t('label.contact') }}</div>
        <div class="mt-3">
          <a href="tel:(995) 555 31 00 00" class="d-flex d-sm-inline-block flex-column text-light-green">(995) 555 31 00 00</a>
          <span class="d-none d-sm-inline-block mx-2">&bull;</span>
          <a href="mailto:info@orbi.ge" :class="mode === 'white' ? 'text-white' : 'text-dark-65'">info@orbi.ge</a></div>
        <div class="d-flex mt-3">
          <a v-for="source in sources" :key="source.name" :href="source.url" target="_blank" class="pr-2">
            <inline-svg :src="getIconPath(source.name)"/>
          </a>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center align-items-sm-end">
        <router-link to="/terms" target="_blank">
          <span class="text-hover-primary cursor-pointer" :class="mode === 'white' ? 'text-white' : 'text-dark-50'">
            {{ $t('label.terms_and_conditions') }}
          </span>
        </router-link>

        <router-link to="#">
          <span class="text-hover-primary cursor-pointer mt-2" :class="mode === 'white' ? 'text-white' : 'text-dark-50'">
            {{ $t('label.security_policy') }}
          </span>
        </router-link>

        <div class="d-flex d-sm-inline-block flex-column text-center mt-5">
          <b-link href="https://play.google.com/store/apps/details?id=com.orbi.myorbi" target="_blank">
            <inline-svg :src="`/media/svg/logos/google-play-store-with${this.mode === 'white' ? '-white' : ''}-text.svg`"/>
          </b-link>
          <b-link href="#" target="_blank" class="d-inline-block mt-5 mt-sm-0 ml-sm-3">
            <inline-svg :src="`/media/svg/logos/app-store-with${this.mode === 'white' ? '-white' : ''}-text.svg`"/>
          </b-link>
        </div>
      </div>
    </div>
    <div class="text-center mt-5 mt-sm-3" :class="{'text-white': mode === 'white'}">© {{ $t('label.orbi_group') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {type: String, default: 'default'}
  },
  data: () => ({
    sources: [
      {name: 'facebook', url: 'https://www.facebook.com/orbi.ge/'},
      {name: 'youtube', url: 'https://www.youtube.com/@MY_ORBI'},
      {name: 'twitter', url: 'https://twitter.com/orbi_group?lang=en'},
      {name: 'instagram', url: 'https://www.instagram.com/orbi_group/?hl=en'},
    ]
  }),
  methods: {
    getIconPath(name) {
      const color = this.mode === 'white' ? 'white-' : '';

      return `/media/svg/orbi/${name}-${color}circle.svg`;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid #6E7173;

  &.white {
    border-top: 1px solid #FFFFFF;
  }
}
</style>